<template>
  <div>
    <!--    表单-->
    <BaseForm
      :componentList="from"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      ref="fromdata"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
    ></BaseForm>
    <div class="main-page-table__header">
      <base-button
        label="新增"
        @click="addSupplier({url:'/addProjectCompany',type:'add'})"
        icon="el-icon-plus"
      ></base-button>
    </div>
    <!--    表格-->
    <Basetable
      :columns="columns"
      :api="api"
      :getApi="'getPage'"
      :showPage="true"
      :loadCount="loadCount"
      :queryParas="queryParas"
      :tableAttrs="{
        data: tableData,
        stripe: true,
      }"
      ref="tableData"
      :dataSource.sync="tableData"
      :webPage="false"
      class="main-page-table with-add"
    >
      <template slot="code" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <template slot="action" slot-scope="scope">
        <IconButton
          @click.prevent="detailsInfo(scope.row.keyId)"
          content="查看"
          icon="iconfont iconchakan"
        ></IconButton>
        <IconButton
          @click.prevent="updataInfo(scope.row.keyId)"
          content="编辑"
          icon="iconfont iconbianji3"
        ></IconButton>
        <IconButton
          @click.prevent="removeListItem(scope.row.keyId)"
          content="删除"
          icon="iconfont iconshanchu1"
        ></IconButton>
      </template>
    </Basetable>

  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import BaseForm from '@/components/common/base-form/base-form'
import Basetable from '@/components/common/table/base-table/base-table'
import { fromConfig, columnsConfig } from './utils/config.js'
import { supplierApi } from '@/api/companyApi'

export default {
  name: 'supplierManagement',
  components: {
    BaseForm,
    Basetable,
    IconButton,
    BaseButton
  },
  data () {
    return {
      queryParas: {
        pageSize: 10,
        pageIndex: 1,
        enterpriseLabel: 2
      },
      loadCount: 0,
      tableData: []
    }
  },
  mounted () {
    this.handleFilter()
  },
  computed: {
    columns () {
      return columnsConfig(this)
    },
    from () {
      return fromConfig(this)
    },
    api () {
      return supplierApi
    }
  },
  methods: {
    /**
     * 查询
     */
    handleFilter () {
      this.loadCount++
    },
    /**
     * 重置
     */
    clearParams () {
      this.queryParas = this.$options.data().queryParas
    },
    /**
     * 新增供应商
     * @param data
     */
    addSupplier (data) {
      this.$router.push({
        path: data.url,
        query: { type: data.type }
      })
    },
    /**
     * 删除
     * @param keyId
     */
    removeListItem (keyId) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        supplierApi.del({ keyId }).then((res) => {
          this.success('删除成功')
          if (res.code === '0') this.handleFilter()
        })
      }).catch(() => {
      })
    },
    /**
     * 编辑
     * @param keyId
     */
    updataInfo (keyId) {
      this.$router.push({
        path: '/addProjectCompany',
        query: {
          keyId: keyId,
          type: 'edit'
        }
      })
    },
    /**
     * 查看详情
     * @param keyId
     */
    detailsInfo (keyId) {
      supplierApi.get(keyId).then((res) => {
        this.$router.push({
          path: '/addProjectCompany',
          query: {
            keyId: keyId,
            type: 'details'
          }
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>

.pd15 {
  padding: 15px;
}
</style>
