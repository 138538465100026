
export const fromConfig = (content) => {
  return [
    {
      label: '企业名称',
      prop: 'enterpriseName',
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      attrs: {
        placeholder: '请输入'
      }
    }
  ]
}

export const columnsConfig = (content) => {
  return [
    {
      label: '序号',
      prop: 'code',
      width: '80px'
    },
    {
      label: '企业名称',
      prop: 'enterpriseName',
      minWidth: '150px'
    },
    {
      label: '统一社会信用代码',
      prop: 'creditCode',
      minWidth: '250px'
    },
    {
      label: '法人代表',
      prop: 'legalRepresentative',
      minWidth: '150px'
    },
    {
      label: '操作',
      prop: 'action',
      width: '155px',
      fixed: 'right'
    }
  ]
}
